import { ReactElement, Component, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { actions, GlobalMessage, RootState, ThunkDispatchType } from '../store';
import { PAGE_URL, PROVIDER } from '../constants';
import GlobalToast from '../components/GlobalToast';
import { 
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token
} from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
//import { SplashScreen } from '@capacitor/splash-screen';
import { Hub } from 'aws-amplify/utils';
import { signInWithRedirect as amplifySignInWithRedirect } from 'aws-amplify/auth/cognito';
import { fetchAuthSession as amplifyFetchAuthSession } from 'aws-amplify/auth';
import { useIntercom, IntercomProps } from 'react-use-intercom';
import api from '../api';
import { CabButton } from '@CabComponents/CabButton';
import { checkForProviderGrant } from './authUtils';
import { useMediaQuery, useTheme } from "@mui/material";
import { cabCaptureException } from './logging';
import { CabModal } from '@CabComponents/CabModal';
import { isPlatform } from './platformUtils';
import 'aws-amplify/auth/enable-oauth-listener';
import { postLoginCookieCleanup } from './storageUtils';
import { DateTime } from 'luxon';

type CustomState = {referralCode?: string; stayLoggedIn?: boolean; redirectPath?: string} | null;

const mapStateToProps = (state: RootState) => (
  {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    organization: state.organization,
    navbarExpanded: state.cabUI.navbarExpanded,
  }
);

const pollLatestVersionIntervalMs = Number(import.meta.env.VITE_POLL_LATEST_VERSION_INTERVAL_SECONDS) * 1000;

// Intercom needs a delay before booting to let the library load, and it needs to be booted before calling `update`
//     or any other methods on it. It's easier to just delay everything intercom gets.
const intercomBootDelay = 2000;

export const IntercomInit = (): ReactElement => {
  const [isBooted, setIsBooted] = useState(false);
  const { update, boot } = useIntercom();
  const theme = useTheme();

  const navbarExpanded = useSelector((root: RootState) => root.cabUI.navbarExpanded);
  const isAuthenticated = useSelector((root: RootState) => root.auth.isAuthenticated);
  const user = useSelector((root: RootState) => root.auth.user);
  const organization = useSelector((root: RootState) => root.organization);

  // useMountEffect(() => {
  //   update({
  //     hideDefaultLauncher: import.meta.env.VITE_DEPLOY_ENV === "prod"
  //   });
  // });
  const isSmDown = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const intercomHorizontalOffset = isSmDown ? 20 : navbarExpanded ? 240 : 64;
    if (!isBooted) {
      window.setTimeout(() => {
        boot({
          hideDefaultLauncher: true,
          customLauncherSelector: "#custom_intercom_launcher",
          alignment: "left",
          horizontalPadding: intercomHorizontalOffset
        });
        setIsBooted(true);
      }, intercomBootDelay + 500);
    } else {
      window.setTimeout(() => update({
        horizontalPadding: intercomHorizontalOffset
      }), isBooted ? 0 : intercomBootDelay + 500);
    }
  }, [navbarExpanded, isSmDown, update, boot, isBooted]);

  useEffect(() => {
    if (isAuthenticated && user && organization && organization.id > 0) {
      const validGrants = Object.values(PROVIDER).filter(p => (
        user && checkForProviderGrant(user.oauth_grant_details, p.name)
      )).map(p => p.name);

      const intercomUser: IntercomProps = {
        name: user.first_name + ' ' + user.last_name,
        email: user.email,
        userId: user.id.toString(),
        customAttributes: {
          calendarOauth: validGrants.sort().join(','),
          tier: user.active_license.tier,
          isOnContract: organization.is_on_contract,
          trialEndDate: organization.subscriptionDetails?.trial_end
            ? DateTime.fromISO(organization.subscriptionDetails?.trial_end).toUnixInteger()
            : undefined,
        },
        company: {
          companyId: organization.id.toString(),
          name: organization.name || "Unknown",
          plan: user.active_license.tier
        }
      };
      window.setInterval(() => (
        update(intercomUser)
      ), intercomBootDelay);
    }
  }, [isAuthenticated, update, user, organization.is_on_contract, organization]);

  return <></>;
};

const ConnectedIntercomInit = IntercomInit;

const mapDispatchToProps = (dispatch: ThunkDispatchType) => bindActionCreators({
  loadUser: actions.auth.loadUser,
  login: actions.auth.login,
  fetchAirlines: actions.staticData.fetchAirlines,
  fetchCountries: actions.staticData.fetchCountries,
  fetchIndustries: actions.staticData.fetchIndustries,
  fetchHotels: actions.staticData.fetchHotels,
  saveDeviceToken: actions.auth.saveDeviceToken,
  sendMessage: actions.globalMessage.sendMessage,
  setRedirectUrl: actions.auth.setAuthRedirect
}, dispatch);

type ComponentProps = (
  ReturnType<typeof mapStateToProps> & 
  ReturnType<typeof mapDispatchToProps>
);

interface ComponentState {
  exitAlertOpen: boolean;
}


export class AppInit extends Component<ComponentProps, ComponentState> {
  tokenRefreshTimeout = setTimeout(() => Function.prototype(), 0);

  // determine if the app is running natively on ios or android, or in a browser
  isMobile = (isPlatform('ios') || isPlatform('android')) && !isPlatform('mobileweb');
  constructor(props: ComponentProps) {
    super(props); 

    // history state persists and we need to make sure state.from.pathname is reset between sessions
    window.history.replaceState({
      ...window.history.state,
      state: { ...window.history.state?.state, from: undefined }
    }, '');

    this.state = {exitAlertOpen: false};

    App.addListener('backButton', (event) => {
      // If the current path is the first history entry, or home/login, exit the app.
      const currentPath = window.location.pathname;
      if (!event.canGoBack || currentPath === PAGE_URL.DASHBOARD || currentPath === PAGE_URL.LOGIN) {
        this.setState({exitAlertOpen: true});
      } else {
        window.history.back();
      }
    });
    
    if (this.isMobile) {
      try {
        // Log error with push notification registration
        PushNotifications.addListener('registrationError', 
          (error) => {
            cabCaptureException(Error('Error on registration: ' + JSON.stringify(error)));
          }
        );

        // Do something with the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived', 
          (notification: PushNotificationSchema) => {
            //alert('Push received: ' + JSON.stringify(notification));
          }
        );

        // Do something when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed', 
          (notification: ActionPerformed) => {
            //alert('Push action performed: ' + JSON.stringify(notification));
          }
        );
      } catch (e) {
        cabCaptureException(e);
      }
    }

    // Hide the splash screen when deviceready is called.
    // cordova-plugin-ionic delays this signal until the live-deploy is complete
    const onDeviceReady = (): void => {
      // NOTE: This is commented because of a bug in Capacitor
      //   We need to set launchShowDuration in SplashScreen settings and let the plugin handle it completely
      //SplashScreen.hide();
    };

    document.addEventListener('deviceready', onDeviceReady, false);
  }

  componentDidUpdate(prevProps: ComponentProps, prevState: ComponentState): void {
    // If the user was not authenticated and then was authenticated, either they:
    // 1. Logged in with user/pass
    // 2. Auto-authenticated by sending over a JWT on page load
    // These are the only 2 situations where a valid (potentially new) JWT will exist
    if (this.props.isAuthenticated && !prevProps.isAuthenticated) {
      if (this.isMobile) {
        try {
          // On success, save the token to the database and associate with the user
          PushNotifications.addListener('registration', 
            (token: Token) => {
              Device.getInfo().then(info => {
                Device.getId().then(deviceId => {
                  const registrationId = token.value;
                  const name = this.props.user ? this.props.user.first_name + ' ' + this.props.user.last_name : '';
                  const type = info.platform;
                  const deviceUuid = deviceId.identifier;
                  this.props.saveDeviceToken(registrationId, name, deviceUuid, type);
                });
              });
              //this.props.saveDeviceToken(token.value);
              //alert('Push registration success, token: ' + token.value);
            }
          );

          PushNotifications.requestPermissions().then(res => {
            if (res.receive === 'granted') {
              // Register with Apple / Google to receive push via APNS/FCM
              PushNotifications.register();
            }
          });
        } catch (e) {
          cabCaptureException(e);
        }
      }
    }
  }

  handleOpenExitAlert = (): void => {
    this.setState({exitAlertOpen: true});
  };

  handleCloseExitAlert = (): void => {
    this.setState({exitAlertOpen: false});
  };

  handleConfirmExit = (): void => {
    // Close the app if they saw the toast message already
    // Extend Navigator type because app is missing
    interface IonicNavigatorExt {
      app: {
        exitApp: () => unknown;
      };
    }
    type IonicNavigator = Navigator & IonicNavigatorExt;
    if (navigator && 'app' in navigator) {
      (navigator as IonicNavigator).app.exitApp();
    } else {
      this.setState({exitAlertOpen: false});
    }
  };

  loginDemo = (): void => {
    this.props.login('guest@company.com', 'Password12#', true);
  };

  componentDidMount = (): void => {
    if (window.DEMO_MODE === '1') {
      amplifyFetchAuthSession()
        .then(user => {
          if (user.tokens) {
            this.props.loadUser();
          } else {
            this.loginDemo();
          }
        })
        .catch(error => {
          this.loginDemo();
        });
    } else  {
      this.props.loadUser();
    }

    Hub.listen('auth', async ({ payload }) => {
      const event = payload.event;
      const data = 'data' in payload ? payload.data : {};
      //const error = (typeof data !== 'string' && 'error' in data) ? data.error : undefined;
      const dataString = data ? data.toString() : '';
      const error = data && typeof data === "object" && 'error' in data ? data.error : null;

      // Tested in simulator - error has a 'code' property, at least sometimes
      const errorCode = error && 'code' in error ? error.code as number : null;

      //const messageContent =  data.message;
      switch (event) {
        case 'signInWithRedirect':
        case 'signedIn': {
          this.props.loadUser(true);
          break;
        }
        case 'tokenRefresh': {
          await postLoginCookieCleanup();
          break;
        }
        case 'signInWithRedirect_failure': {
          if (errorCode && errorCode in [18]) {
            // Code 18: Error: Failed to execute 'replaceState' on 'History': A history state object with URL 
            //          'joincabinet://localhost/login' cannot be created in a document with origin 'https://localhost' 
            //          and URL 'https://localhost/login?code=xxxx
            //          This happens because we are changing the protocol from joincabinet:// to https:// post-deeplink
            break;
          }

          const authMessage: GlobalMessage = {
            timeout: 5000,
            header: "",
            message: "There was a problem with your authentication provider.",
            autoDismiss: true,
            position: {vertical: "bottom", horizontal: "left"},
            active: true,
            severity: "error",
          };
          if (dataString.startsWith('Error: Already+found+an+entry+for+username')) {
            amplifySignInWithRedirect({provider: "Google"});
          } else if (dataString.startsWith('Error: Bad+id_token+issuer')) {
            authMessage["message"] = "There was a problem with your SSO connection.";
            this.props.sendMessage(authMessage);
            cabCaptureException(Error(dataString));
          } else if (dataString.startsWith("NotAuthorizedException")) {
            // pass
          } else if (dataString.startsWith("PasswordResetRequiredException")) {
            // pass
          } else {
            this.props.sendMessage(authMessage);
            cabCaptureException(Error(dataString));
          }
          break;
        }
        case 'signedOut':
          break;
        case 'customOAuthState': {
          let customState: CustomState = null;
          try {
            customState = JSON.parse(typeof data === 'string' ? data : '') as CustomState;
            
            if (customState?.referralCode) {
              // TODO: Handle referral code here
            }
            if (customState?.stayLoggedIn) {
              // TODO: Handle clearing refresh token here
            }
            if (customState?.redirectPath) {
              // do not set this redirect url if we are already on that page
              if (window.location.pathname !== customState.redirectPath) {
                this.props.setRedirectUrl(customState.redirectPath);
              }
            }
          } catch (e) {
            cabCaptureException(e);
            break;
          }
        }
      }
    });

    if (!window.IS_NATIVE_ANDROID && !window.IS_NATIVE_IOS && pollLatestVersionIntervalMs) {
      window.setInterval(async () => {
        const latestVersion = await api.fetchLatestVersion();
        const currentVersion = import.meta.env.VITE_APP_FRONTEND_VERSION;
        if (latestVersion && currentVersion) {
          // Just compare against the semver
          // https://regex101.com/r/K4IpoC/1
          // This should catch the version number if it's in one of the following formats:
          // 1.13.23-h1 => 1.13.23-h1
          // app-frontend.web.prod.1.13.23-h1 => 1.13.23-h1
          // eslint-disable-next-line no-useless-escape
          const re = /(?:[\w-]*?\.[\w-].*?\.[\w-]*?\.)?([a-z0-9\.-]*).*/;
          const latestMatch = latestVersion.match(re);
          const currentMatch = currentVersion.match(re);
          if (latestMatch && latestMatch.length > 1 && currentMatch && currentMatch.length > 1) {
            const latestSemver = latestMatch[1];
            const currentSemver = currentMatch[1];
            if (latestSemver !== currentSemver) {
              console.log(`New Version of Cabinet Available! [${currentSemver} => ${latestSemver}]`);
              const message: GlobalMessage = {
                timeout: pollLatestVersionIntervalMs - 5000,
                header: "Update Available!",
                message: "Refresh this page to get the latest version of Cabinet",
                autoDismiss: true,
                position: {vertical: "bottom", horizontal: "right"},
                active: true,
                severity: "success",
                action: <CabButton buttonType='tertiary' size="small" onClick={() => window.location.reload()}>
                  REFRESH
                </CabButton>
              };
              this.props.sendMessage(message);
            } else {
              console.log(`On Latest Version [${currentSemver}]`);
            }     
          }       
        }
      }, pollLatestVersionIntervalMs);
    }
  };

  render(): ReactElement {
    return (
      <>
        <CabModal
          open={this.state.exitAlertOpen}
          onClose={() => this.handleCloseExitAlert}
          isAlert
          title="Exit the app?"
          actionButtons={<>
            <CabButton buttonType='tertiary' color='primary' onClick={() => this.handleCloseExitAlert}>
              No
            </CabButton>
            <CabButton 
              onClick={() => this.handleConfirmExit}
            >
              Yes, delete it
            </CabButton>
          </>}
        />
        <GlobalToast />
        <ConnectedIntercomInit/>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppInit);
